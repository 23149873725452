import React from 'react';
import avatar from './avatar.png';
import './App.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

function App() {
  return (
    <div className='flex flex-row'>
      <div className='divide-x-4 border-l-4 border-app-pink w-10 ml-10'></div>
      <div className='flex flex-col py-12 divide-x-4 border-app-pink'>
        <div className='grid gap-4 grid-cols-2 justify-items-center items-center app-bg-white basis-19/20'>
          <div className='grid gap-2 grid-rows-2 h-1/5 basis-2/3 h-3/5'>
            <div className='basis-4/5 h-4/5 pl-64'>
              <div className='text-xl'>Hi there 👋!</div>
              <div className='text-4xl flex'>I'm {<div className='text-app-pink'>&nbsp;Bright Johnson&nbsp;</div>} {' '} Ayebale</div>
              <div className='text-sm'>I'm a software engineer. I work on the web and have experience build web applications using a number of technologies including JavaScript, React, Angular, AngularJS, NestJS and Ruby on Rails. I also have experience working with Java(Springboot), Rust, Ruby and Java. I'm currently based in Uganda and I've remotely with companies all over the world.</div>
            </div>
            <div className='text-sm basis-1/5 h-1/5 pl-64'>
              <a href='https://docs.google.com/document/d/1UyVk5cx4lyR-y1pDN6NiJKw_LT1ppaTD/export?format=pdf'>
                <button className='bg-app-pink text-app-bg-white px-3 py-2'>DOWNLOAD RESUME</button>
              </a>
            </div>
          </div>
          <div className='basis-1/3'>
            <img src={avatar} className='w-1/2 my-auto mx-auto' alt="dp" />
          </div>
        </div>
        <div className='flex justify-start pl-20 border-0'>
          <a href='https://www.linkedin.com/in/ayebale-bright-johnson-7b959586/' target='_blank' rel='noreferrer'><FontAwesomeIcon size='2x' icon={faLinkedin} className='mr-5 text-linkedin-blue' /></a>
          <a href='https://github.com/Pato91' target='_blank' rel='noreferrer'><FontAwesomeIcon size='2x' icon={faGithub} /></a>
        </div>
      </div>
    </div>
  );
}

export default App;
